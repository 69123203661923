

import React, { Component } from 'react'
import { navigate } from 'gatsby'

class MiniRedirect extends Component {

  componentDidMount() {
    const url = typeof window !== 'undefined' ? window.location.hash : '';
    if(url.indexOf("autocross") > -1) {
      navigate('/mini/schools')
    }else if(url.indexOf("stunt") > -1) {
      navigate('/mini/schools')
    } else {
      navigate('/mini/schools')
    }
  }
  
  render() {
    return (
      <>
      </>
    )
  }
}

export default MiniRedirect
